<template>
    <section class="section section_type_dealer is_new-design">

        <section class="section__body body">
            <div class="logo__wrap">
                <img :src="loginLogo">
            </div>

            <div class="info_wrap">
                <div class="card card_type_package-units pb__12 mb__40">
                    <div class="card__head ptb__16 plr__20">
                        <h2>{{dealer.legal_name}}</h2>
                    </div>
                    <table class="table">
                        <tr v-if="dealerLogin">
                            <th>{{ $t('Dealer.Email') }}:</th>
                            <td class="overflow-text text-width">{{ dealerLogin }}</td>
                            <td>
                                <button class="button button_view_icon button_theme_mute mtb__-4">
                                    <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__copy"/></span> </span>
                                </button>
                            </td>
                        </tr>
                        <tr v-if="dealer.phone">
                            <th>{{ $t('Dealer.Phone') }}:</th>
                            <td>{{dealer.phone}}</td>
                            <td>
                                <button class="button button_view_icon button_theme_mute mtb__-4">
                                    <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__copy"/></span> </span>
                                </button>
                            </td>
                        </tr>
                        <tr v-if="dealer.contact_person">
                            <th>{{$t('Dealer.Contact person')}}:</th>
                            <td>{{dealer.contact_person}}</td>
                            <td>
                                <button class="button button_view_icon button_theme_mute mtb__-4">
                                    <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__copy"/></span> </span>
                                </button>
                            </td>
                        </tr>
                        <tr v-if="false">
                            <th>{{ $t('Dealer.Address') }}:</th>
                            <td>Address address address address address address address</td>
                            <td>
                                <button class="button button_view_icon button_theme_mute mtb__-4">
                                    <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__copy"/></span> </span>
                                </button>
                            </td>
                        </tr>
                    </table>
                </div>

                <FieldGroup_input
                        :field-group_class="'mb__12'"
                        :id="'dealer__amount'"
                        :label="isDealer? $t('Dealer.Keys amount') : $t('Dealer.Units amount')"
                        :error="errors.amount"
                        v-model="amount"
                />
                <button class="button button_view_base button_theme_-primary button_theme_neutral button_width_100p"
                        @click="onSendRequest"
                >
                    {{ $t('Dealer.buttons.Send Request') }}
                </button>
            </div>
        </section>
    </section>
</template>

<script>
    import {mapGetters, mapActions, mapMutations} from "vuex";
    import {required, integer, minValue} from "vuelidate/lib/validators";

    export default {
        name: "Dealer",
        props: [],
        components: {},
        data() {
            return {
                amount: 0,
                errors: {},
            }
        },
        validations: {
            amount: { required, integer, minValue: minValue(1) }
        },
        computed: {
            ...mapGetters([
                "isDealer",
                "apiToken",
                "getAppUser",
            ]),

            dealer(){
                return this.getAppUser.dealer || {}
            },
            dealerLogin(){
                return (this.dealer.email > '' ? this.dealer.email : (this.dealer.login > '' ? this.dealer.login : '') )
            },
            loginLogo(){
                let loginLogo = this.getAppUser.dealer_login_logo || {}
                return  loginLogo ? loginLogo.url+'?h='+loginLogo.hash : require('@/assets/img/empty1px.png')
            },
        },
        methods: {
            ...mapMutations([
            ]),
            ...mapActions([
                "sendUserRequest",
                "setPopup"
            ]),
            validateOrder(){
                this.$v.$touch()
                this.errors = {}
                // this.componentsErrors = [];
                let hasError;
                if (this.$v.$invalid) {
                    //if(components.includes(''))
                    {
                        hasError = false;
                        //amount
                        if(!this.$v.amount.required || !this.$v.amount.integer || !this.$v.amount.minValue){
                            hasError =
                            this.errors.amount= 'Amount is required and must be minimum 1'
                        }

                        if(hasError){
                            // this.componentsErrors.push('')
                        }
                    }
                }
            },
            onSendRequest(){
                this.validateOrder()
                if(Object.keys(this.errors).length)
                    return false // stop here if form is invalid

                let title = this.isDealer ? this.$t('Dealer.Keys amount') : this.$t('Dealer.Units amount')
                title = title +': '+ this.amount
                this.setPopup({
                    title,
                    //text: 'Amount: '+this.amount,
                    methods: {
                        ok: () => this.sendRequest(),
                        //cancel: () => {},
                    }
                })
            },
            sendRequest(){
                let request = {
                    id: this.getAppUser.id,
                    type: this.isDealer ? 'keys' : 'units',
                    amount: this.amount
                }
                this.sendUserRequest(request)
                    .then((/*res*/) => {
                        //console.log(res)
                        this.errors = {}
                        this.$toast.success(this.$t("success!"))
                        this.$emit('close-dialog')
                    })
                    .catch((error) => {
                        // Error
                        if (error.response) {
                            console.error(error.response)
                            if(error.response.status == 422){
                                this.errors = error.response.data.reduce((out, error) => {
                                    out[error.field] = error.message
                                    return out
                                }, {})
                            }
                        } else if (error.request) {
                            console.error(error.request);
                        } else {
                            console.error('Error', error.message);
                        }
                        //console.error(error.config);
                    })
            },
        },
        created() {
        },
        mounted() {
        },
        updated() {
        }
    }
</script>

<style scoped>
.info_wrap .table > tr > td:last-child{
    width: 56px;
}
.overflow-text{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.text-width {
    max-width: 240px;
}
</style>